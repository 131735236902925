// @ts-strict-ignore
import React, { MouseEvent } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import { COLUMNS_AND_STATS, ITEM_TYPES } from '@/trendData/trendData.constants';
import { formatNumber, FormatOptions } from '@/utilities/numberHelper.utilities';
import { isStringSeries } from '@/utilities/utilities';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import i18next from 'i18next';
import {
  ConditionDataCellHelperProperties,
  ConditionTimeCellHelperProperties,
  DEFAULT_BACKGROUND_DARK,
  DEFAULT_BACKGROUND_LIGHT,
  DEFAULT_TEXT_COLOR_DARK,
  DEFAULT_TEXT_COLOR_LIGHT,
  NULL_PLACEHOLDER,
  SimpleDataCellHelperProperties,
  SimpleTextCellHelperProperties,
  STRIPED_CELL_COLOR,
  STRIPED_CELL_COLOR_DARK,
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TextHeaderMenuActions,
} from '@/tableBuilder/tableBuilder.constants';
import { ANALYSIS_COLORS } from '@/trend/trendViewer/trendViewer.constants';
import { ConditionTableHeader, TableBuilderHeaders, TableColumn } from '@/tableBuilder/tableBuilder.types';
import { RangeExport } from '@/trendData/duration.store';
import { TableBuilderTextCell } from '@/tableBuilder/tableComponents/TableBuilderTextCell.atom';
import { TableBuilderDataCell } from '@/tableBuilder/tableComponents/TableBuilderDataCell.atom';

const isReadableColorCache = new Map();

/**
 * Formats the text for a column header.
 *
 * @param headerInfo - Header formatting information
 * @param headerInfo.type - One of TableBuilderHeaderType enumeration
 * @param headerInfo.format - Format string to use if .type is a format involving date/times
 * @param property - Custom property
 * @param startTime - Start time for the cell
 * @param endTime - End time for the cell
 * @param timezone - timezone to run the table for
 * @returns The formatted header
 */
export function formatHeader(
  headerInfo: { type: TableBuilderHeaderType; format: string },
  property: string | undefined,
  startTime: number,
  endTime: number,
  timezone: { name: string },
): string {
  const formatDate = (date, isStart) =>
    _.isNil(date)
      ? i18next.t(`TABLE_BUILDER.${isStart ? 'STARTS_OUT_OF_RANGE' : 'ENDS_OUT_OF_RANGE'}`)
      : moment.utc(date).tz(timezone.name).format(headerInfo.format);
  if (headerInfo.type === TableBuilderHeaderType.None) {
    return '';
  } else if (headerInfo.type === TableBuilderHeaderType.CapsuleProperty) {
    return property ?? '';
  } else if (headerInfo.type === TableBuilderHeaderType.Start) {
    return formatDate(startTime, true);
  } else if (headerInfo.type === TableBuilderHeaderType.End) {
    return formatDate(endTime, false);
  } else {
    return `${formatDate(startTime, true)} - ${formatDate(endTime, false)}`;
  }
}

/**
 * Formats a metric value for display in the scorecard table.
 *
 * @param value - The value of the metric
 * @param formatOptions - the format options to be used when formatting the cell
 * @param [column] - One of the columns from COLUMNS
 * @returns The formatted value
 */
export function formatMetricValue(value: any, formatOptions: FormatOptions, column: any = {}): string {
  if (_.isNil(value)) {
    return column.style === 'string' ? '' : NULL_PLACEHOLDER;
  } else if (_.isNumber(value)) {
    return formatNumber(
      value,
      {
        ...formatOptions,
        format: column.format || formatOptions?.format,
      },
      _.noop,
    );
  } else {
    return value;
  }
}

/**
 * Computes the style for cell.
 *
 * @param backgroundColor - Background color.
 * @param textColor - Text color
 * @param textStyle - An array with text styles
 * @param textAlign - Text align
 * @param priorityBackgroundColor - Overwrites the backgroundColor.
 * @param fallbackBackgroundColor - Fallback background color. It is used if backgroundColor and
 *   priorityBackgroundColor are not present
 * @returns An object containing HTML styles attributes and values
 */
export function computeCellStyle(
  backgroundColor: string = undefined,
  textColor: string = undefined,
  textStyle: string[] = [],
  textAlign = 'left',
  priorityBackgroundColor: string = undefined,
  fallbackBackgroundColor: string = undefined,
  darkMode = false,
): {
  backgroundColor: string;
  color: string;
  fontWeight: string;
  fontStyle: string;
  textDecoration: string;
  textAlign: string;
} {
  const hasPriorityColor = !_.isUndefined(priorityBackgroundColor);
  const modeBasedTextColor = darkMode ? DEFAULT_TEXT_COLOR_DARK : DEFAULT_TEXT_COLOR_LIGHT;
  const appliedTextColor = textColor ? textColor : modeBasedTextColor;
  let noPriorityBackgroundColor;
  if (!_.isUndefined(backgroundColor)) {
    noPriorityBackgroundColor = backgroundColor;
  } else if (!_.isUndefined(fallbackBackgroundColor)) {
    noPriorityBackgroundColor = fallbackBackgroundColor;
  } else {
    noPriorityBackgroundColor = darkMode ? DEFAULT_BACKGROUND_DARK : DEFAULT_BACKGROUND_LIGHT;
  }

  const resultBackgroundColor = hasPriorityColor ? priorityBackgroundColor : noPriorityBackgroundColor;

  const blackOrWhite = tinycolor(priorityBackgroundColor).isDark() ? '#fff' : '#000';

  // check readability when we have metric color (priorityBackgroundColor) and overwrite text color if necessary
  const resultTextColor =
    hasPriorityColor && !isReadableColor(priorityBackgroundColor, appliedTextColor) ? blackOrWhite : appliedTextColor;

  return {
    backgroundColor: resultBackgroundColor,
    color: resultTextColor,
    fontWeight: _.includes(textStyle, 'bold') ? 'bold' : 'normal',
    fontStyle: _.includes(textStyle, 'italic') ? 'italic' : 'normal',
    textDecoration: _.filter(textStyle, (textDecoration) =>
      _.includes(['overline', 'line-through', 'underline'], textDecoration),
    ).join(' '),
    textAlign,
  };
}

/**
 * Cached version of {@code tinycolor.isReadable}. The original function is not particularly slow but when calling
 * it many times in tables with thousands of cells the cache improves performance.
 * @param backgroundColor - The background color.
 * @param textColor - The text color.
 * @return True if the text is readable on the specified background
 */
function isReadableColor(backgroundColor: string, textColor: string): boolean {
  const cacheKey = backgroundColor + textColor;
  let isReadable = isReadableColorCache.get(cacheKey);
  if (_.isUndefined(isReadable)) {
    isReadable = tinycolor.isReadable(backgroundColor, textColor);
    isReadableColorCache.set(cacheKey, isReadable);
  }
  return isReadable;
}

/**
 * Computes a foreground that contrasts with that color so that it is readable.
 *
 * @param backgroundColor - The background color. Default to white if not specified.
 * @param darkMode - true if dark mode is used to render the application
 * @return Styles for the background and foreground colors
 */
export function computeCellColors(
  backgroundColor: string | undefined,
  darkMode: boolean | undefined,
): {
  backgroundColor: string;
  color: string;
} {
  const defaultBackgroundColor = darkMode ? DEFAULT_BACKGROUND_DARK : DEFAULT_BACKGROUND_LIGHT;
  const color = backgroundColor ?? defaultBackgroundColor;
  const defaultTextColor = darkMode ? DEFAULT_TEXT_COLOR_DARK : '#000';
  return {
    backgroundColor: color,
    color: isReadableColor(color, defaultTextColor) ? defaultTextColor : '#fff',
  };
}

/**
 * Gets the striped color based on the current state of isTableStriped and the row index passed in
 */
export function getStripedColor(isStriped: boolean, rowIndex: number, darkMode: boolean): string | undefined {
  const stripedCellColor = darkMode ? STRIPED_CELL_COLOR_DARK : STRIPED_CELL_COLOR;
  return isStriped && rowIndex % 2 === 0 ? stripedCellColor : undefined;
}

export function hasNumericAndStringItems(items: { itemType: string }[], itemType: ITEM_TYPES) {
  return _.chain(items)
    .filter((item) => item.itemType === itemType)
    .partition((signal) => isStringSeries(signal))
    .every((group) => !_.isEmpty(group))
    .value();
}

export function getMostReadableIconType(background = '#fff') {
  const mostReadable = tinycolor.mostReadable(background, [ANALYSIS_COLORS.DARK_PRIMARY, ANALYSIS_COLORS.LIGHT_COLOR]);
  return mostReadable.toString() === ANALYSIS_COLORS.DARK_PRIMARY ? 'theme' : 'theme-light';
}

export function copyToClipboard(tableBuilderRef: any) {
  try {
    if (!tableBuilderRef) {
      errorToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_EMPTY' });
    } else {
      const selection = window.getSelection();
      selection.removeAllRanges();
      const range = document.createRange();
      range.selectNodeContents(tableBuilderRef);
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      successToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_SUCCESS' });
    }
  } catch (err) {
    errorToast({ messageKey: 'TABLE_BUILDER.COPY_TO_CLIPBOARD_ERROR' });
  }
}

export function findParent(node: HTMLElement, type: string, className?: string): HTMLElement {
  let parentNode: HTMLElement = node?.parentElement;
  while (
    parentNode?.parentElement &&
    !(parentNode.nodeName === type && (!className || parentNode.className.split(' ').includes(className)))
  ) {
    parentNode = parentNode.parentElement;
  }
  return parentNode;
}

/**
 * Finds the size of the earliest parent node of the given node with the given type
 * @param node
 * @param type
 * @param className
 * @returns The height and width of the parent node, or undefined
 */
export function findParentSize(node: HTMLElement, type: string, className?: string): { height: number; width: number } {
  const parentNode = findParent(node, type, className);
  return parentNode ? { height: parentNode.clientHeight, width: parentNode.clientWidth } : undefined;
}

/**
 * Utility method that determines if a given table column is a text column
 * @param column table column
 * @returns True if the given column is a text column, false otherwise
 */
export const isTextColumn = (column: TableColumn) =>
  _.isUndefined(column.style) ? false : ['assets', 'string', 'fullpath'].includes(column.style);

export const isStartOrEndColumn = (column) =>
  _.includes([COLUMNS_AND_STATS.startTime.key, COLUMNS_AND_STATS.endTime.key], column?.key);

export const getTextValueForConditionHeader = (header: ConditionTableHeader, column) =>
  _.cond([
    [_.matches({ type: TableBuilderColumnType.Text }), (column: any) => column.cells && column.cells[header.key]],
    [_.matches({ key: COLUMNS_AND_STATS.name.key }), () => header.name],
    [_.matches({ key: COLUMNS_AND_STATS.valueUnitOfMeasure.key }), () => header.units],
  ])(column);

export const getMenuActions = (columnType: string, isPresentationMode: boolean, isViewOnlyMode: boolean) => {
  if (isPresentationMode) {
    return [];
  }

  if (columnType === TableBuilderColumnType.Text) {
    return isViewOnlyMode
      ? []
      : _.values(TextHeaderMenuActions).filter(
          (action) => !_.includes([TextHeaderMenuActions.Filter, TextHeaderMenuActions.Sort], action),
        );
  } else {
    return isViewOnlyMode
      ? [TextHeaderMenuActions.Filter, TextHeaderMenuActions.Sort]
      : _.values(TextHeaderMenuActions);
  }
};

export const getExtraHeaderProps = (
  column: any,
  headers: TableBuilderHeaders,
  displayRange: RangeExport,
  timezone: { name: string },
): { isInput: boolean; textValue: string; isStatic?: boolean } => {
  if (column.headerOverridden) {
    const textValue = formatHeader(
      headers,
      column.property,
      displayRange.start.valueOf(),
      displayRange.end.valueOf(),
      timezone,
    );
    return { textValue, isInput: false, isStatic: true };
  }
  // check for undefined. Empty string is OK - the user removed the title
  const textValue =
    column.header ?? (column.type === TableBuilderColumnType.Property ? column.key : i18next.t(column.shortTitle));

  return { textValue, isInput: true };
};

/**
 * Returns the TextHeaderMenuActions for alternative text headers (for the metric/stat/property rows)
 * For metrics, should include [Filter] if we're not in presentation mode, or [] in presentation mode.
 * For stats/properties, should include [Rename, Filter, Remove] in edit mode, or [Filter] in view-only mode, or
 * nothing in presentation mode.
 *
 * @param isPresentationMode - true if it is presentation mode
 * @param canEdit - true if the user can edit
 * @param [statOrPropertyColumn] - the stat or property column for the table column (optional)
 */
export const getMenuActionsForAlternativeHeader = (
  isPresentationMode: boolean,
  canEdit: boolean,
  statOrPropertyColumn?,
) => {
  const menuActions = [];
  if (!isPresentationMode) {
    menuActions.push(TextHeaderMenuActions.Sort);
    if (!isStartOrEndColumn(statOrPropertyColumn)) {
      menuActions.push(TextHeaderMenuActions.Filter);
    }
  }
  if (statOrPropertyColumn && canEdit) {
    menuActions.push(TextHeaderMenuActions.Rename, TextHeaderMenuActions.Remove);
  }
  return menuActions;
};

export function renderSimpleTextCell(props: SimpleTextCellHelperProperties) {
  const { columnIndex, key, column, cell, maybeStripedColor, darkMode, canEdit, setCellText, row, isAgGrid } = props;
  return (
    <TableBuilderTextCell
      columnIndex={columnIndex}
      key={key}
      textValue={column.cells && column.cells[row.itemId]}
      style={computeCellStyle(
        column.backgroundColor,
        column.textColor,
        column.textStyle,
        column.textAlign,
        cell?.priorityColor,
        maybeStripedColor,
        darkMode,
      )}
      onTextChange={(value) => setCellText(column.key, value, row.itemId)}
      canEditCellText={canEdit}
      isAgGrid={isAgGrid}
    />
  );
}

export function renderSimpleDataCell(props: SimpleDataCellHelperProperties) {
  const {
    column,
    columnIndex,
    cell,
    key,
    darkMode,
    maybeStripedColor,
    showUnitInSeparateColumn,
    displayRange,
    displayMetricOnTrend,
    row,
    isAgGrid,
  } = props;
  return (
    <TableBuilderDataCell
      columnIndex={columnIndex}
      extraClassNames={cell?.metricId ? 'cursorPointer text-underline-onhover' : ''}
      key={key}
      style={computeCellStyle(
        column.backgroundColor,
        column.textColor,
        column.textStyle,
        column.textAlign,
        cell?.priorityColor,
        maybeStripedColor,
        darkMode,
      )}
      textValue={cell?.value}
      showUnit={!showUnitInSeparateColumn || column.style === 'percent'}
      units={cell?.units}
      onClick={(event) =>
        cell?.metricId &&
        displayMetricOnTrend(
          cell?.metricId,
          row.itemId,
          displayRange.start.valueOf(),
          displayRange.end.valueOf(),
          event,
        )
      }
      t={i18next.t}
      isAgGrid={isAgGrid}
    />
  );
}

export function renderConditionDataCell(props: ConditionDataCellHelperProperties) {
  const {
    value,
    displayMetricOnTrend,
    showUnitInSeparateColumn,
    columnIndex,
    maybeStripedColor,
    capsule,
    darkMode,
    key,
    header,
    isAgGrid,
  } = props;
  return (
    <TableBuilderDataCell
      onClick={(event: MouseEvent) =>
        value.formulaItemId &&
        displayMetricOnTrend(value.formulaItemId, value.itemId, capsule.startTime, capsule.endTime, event)
      }
      textValue={value.value.toString()}
      units={header.units}
      showUnit={!showUnitInSeparateColumn}
      columnIndex={columnIndex}
      key={key}
      extraClassNames={value.formulaItemId ? 'cursorPointer text-underline-onhover' : ''}
      style={computeCellColors(
        _.isNil(value.priorityColor) || value.priorityColor === '#ffffff' ? maybeStripedColor : value.priorityColor,
        darkMode,
      )}
      t={i18next.t}
      isAgGrid={isAgGrid}
    />
  );
}

export function renderConditionTimeCell(props: ConditionTimeCellHelperProperties) {
  const { columnIndex, key, capsule, headers, darkMode, maybeStripedColor, timezone, header, isAgGrid } = props;
  return (
    <TableBuilderDataCell
      textValue={formatHeader(
        {
          ...headers,
          type:
            header.key === COLUMNS_AND_STATS.startTime.key ? TableBuilderHeaderType.Start : TableBuilderHeaderType.End,
        },
        capsule.property,
        capsule.startTime,
        capsule.endTime,
        timezone,
      )}
      columnIndex={columnIndex}
      style={computeCellColors(maybeStripedColor, darkMode)}
      key={key}
      t={i18next.t}
      isAgGrid={isAgGrid}
    />
  );
}
